html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

div#root {
  height: 100%;
  width: 100%;
}

div.App {
  height: 100%;
  width: 100%;
}

div.PhysicsDiv {
  height: 100%;
  width: 100%;
}

canvas.canvas-physics {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: white;
}

div.bio_block {
  mix-blend-mode: multiply;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  float: right;
  z-index: 3;
  left: 40%;
  right: 5%;
  margin: 0;
  bottom: 10%;
}

div.bio_block h3 {
  word-spacing: -0.5rem;
}

div.desc_block {
  padding: 0.5rem 2rem 0.5rem 2rem;
  line-height: 1em;
  font-size: 1.75rem;
  word-wrap: break-word;
  white-space: normal;
  color: white;
  background-color: black;
}

a {
  display: flex;
  text-decoration: none;
}

button.resume_button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-style: solid;
  border-width: 0.35rem;
  color: black;
  border-color: black;
  font-weight: 700;
  font-size: 1.75rem;
  padding: 1.5rem;
  cursor: pointer;
  min-width: 10rem;
  margin-right: 1rem;
  transition: color 0.3s, width 0.1s, background-color 0.1s, border-left-color 0.1s, border-width 0.1s;
}

button.resume_button:hover {
  color: white;
  background-color: black;
  font-size: 1.75rem;
  border-left-color: white;
  border-width: 0px 5px 0px 5px;
}

div.name_block {
  position: absolute;
  float: left;
  z-index: 3;
  vertical-align: top;
  margin: 0;
  padding: 0;
  padding-left: 5%;
  padding-top: 1%;
  line-height: 1rem;
  font-size: 4rem;
  color: black;
}

@media screen and (max-width: 1280px) {
  div.bio_block {
    left: 20%;
  }
}

@media screen and (max-width: 992px) {
  div.name_block {
    font-size: 3rem;
  }

  div.desc_block {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 880px) {
  div.bio_block {
    left: 10%;
  }
}

@media screen and (max-width: 692px) {
  div.name_block {
    font-size: 3rem;
    line-height: 7rem;
  }

  h4 {
    line-height: 0rem;
  }

  div.bio_block {
    display: inherit;
    font-size: 1.25em;
    line-height: 1.5rem;
    left: 50%;
    right: 50%;
    min-width: 90%;
    bottom: 10%;
    margin-right: 0;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }

  button.resume_button {
    width: 100%;
    padding: 1rem;
    margin-right: 0;
    transition: color 0.3s, height 0.1s, background-color 0.1s, border-top-color 0.1s, border-width 0.1s;
  }

  button.resume_button:hover {
    border-left-color: black;
    border-top-color: white;
    margin-top: 0.35rem;
  }
}

@media screen and (max-width: 480px) {
  div.name_block {
    font-size: 2.5rem;
    line-height: 5rem;
    left: 50%;
    top: 0;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }

  div.bio_block {
    bottom: 5%;
  }

  div.bio_block h3 {
    font-size: 1em;
    word-spacing: -0.4rem;
  }

  h4 {
    line-height: 2.5rem;
  }
}

@media screen and (max-height: 620px) {

  div.bio_block {
    left: 50%;
    right: 50%;
    min-width: 90%;
    bottom: 10%;
    margin-right: 0;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }

  button.resume_button:hover {
    width: 20%;
  }

  @media screen and (max-width: 692px) {
    button.resume_button:hover {
      width: 100%;
    }
  }
}

@media screen and (max-height: 500px) {
  div.name_block h4 {
    display: none;
  }
}
